import { gql } from "apollo-angular";

export const authQueries = {
  login_mutation: gql`
    mutation Mutation($email: String, $password: String) {
      login(email: $email, password: $password) {
        token
        user {
          email
          firstName
          id
          lastName
          phoneNumber
          role
          roleId
        }
      }
    }
  `,

  forgot_password: gql`
    mutation Mutation($email: String) {
      forgotPassword(email: $email) {
        message
      }
    }
  `,

  new_password: gql`
  mutation Mutation($email: String, $password: String, $otp: String) {
    resetPassword(email: $email, password: $password, otp: $otp) {
        token
        user {
          email
          firstName
          id
          lastName
          phoneNumber
          role
          roleId
        }
      }
    }  
  `
};
