import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { authQueries } from "../graphql/global.query";
import {
  LoginRequest,
  LoginResponse,
  NewPasswordRequest,
  NewPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
} from "../interfaces/auth.model";
import { Router } from "@angular/router";
import { Messages } from "../constants/messages.enum";
import { User } from "../interfaces/user.model";
import { Observable, Subject } from "rxjs";
import { UserType } from "../constants/userType.enum";
import { UtilsService } from "./utils.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  loggedInUser: User | null = null;
  userLoggedInSubject: Subject<User> = new Subject();

  constructor(
    private apiService: ApiService,
    private router: Router,
    private utilsService: UtilsService
  ) {}

  loginUser(email: string, password: string) {
    const requestVariable = {
      email,
      password,
    };
    this.apiService
      .mutation<LoginRequest, LoginResponse>(
        {
          query: authQueries.login_mutation,
          showToastr: true,
          message: {
            successMessage: Messages.LoginSuccess,
            errorMessage: Messages.LoginError,
          },
        },
        requestVariable
      )
      .subscribe((data) => {
        data.login.user.role = data.login.user.roleId === 1 ? UserType.Admin : UserType.User;
        this.utilsService.setItemLocalStorage("token", data.login.token);
        this.utilsService.setItemLocalStorage(
          "user",
          JSON.stringify(data.login.user)
        );
        this.loggedInUser = data.login.user;
        if(this.loggedInUser.roleId !== 1) {
          this.router.navigateByUrl("project");
        }else{
          this.router.navigateByUrl("organization");
        }
        this.userLoggedInSubject.next(data.login.user);
      });
  }

  logoutUser() {
    this.loggedInUser = null;
    if (this.utilsService.isBrowser) {
      this.utilsService.clearLocalStorage();
    }
    this.router.navigateByUrl("/");
  }

  getLoggedInUser(): User {
    let user: User = {
      firstName: "",
      lastName: "",
      email: "",
      id: -1,
      phoneNumber: "",
      role: UserType.User,
      roleId: 1,
    };
    user = JSON.parse(this.utilsService.getItemLocalStorage("user") || "{}");
    return user;
  }

  getToken(): string {
    const token = this.utilsService.getItemLocalStorage("token") ?? "";
    return token;
  }

  resetPassword(mail: string): Observable<ResetPasswordResponse> {
    return this.apiService.mutation<
      ResetPasswordRequest,
      ResetPasswordResponse
    >(
      {
        query: authQueries.forgot_password,
      },
      {
        email: mail,
      }
    );
  }

  resetNewPassword(otp: string, newPassword: string, mail: string) {
    return this.apiService
      .mutation<NewPasswordRequest, NewPasswordResponse>(
        {
          query: authQueries.new_password,
          showToastr: true,
          message: {
            successMessage: "Password updated successfully",
          },
        },
        {
          email: mail,
          otp: otp,
          password: newPassword,
        }
      )
      .subscribe((response) => {
        if (
          response.resetPassword.token &&
          response.resetPassword.user.role === "User"
        ) {
          localStorage.setItem("token", response.resetPassword.token);
          localStorage.setItem(
            "user",
            JSON.stringify(response.resetPassword.user)
          );
          window.location.href = "projects";
        }
        if (
          response.resetPassword.token &&
          response.resetPassword.user.role === "Admin"
        ) {
          this.utilsService.setItemLocalStorage(
            "token",
            response.resetPassword.token
          );
          this.utilsService.setItemLocalStorage(
            "user",
            JSON.stringify(response.resetPassword.user)
          );
          window.location.href = "users";
        }
      });
  }
}
